.badge {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 300;
  font-size: 0.7rem;
  color: #fff;
  background-color: $color-1-primary;
  border-radius: 2px;
  min-width: 3rem;
  padding: 3px 6px;
  margin-left: 14px;
  text-align: center;
  line-height: 22px;
  height: 22px;

  &.android {
    background-color: #4CAF50;
  }

  &.apple {
    background-color: #0084ff;
  }

  &.not_available {
    background-color: #9e9e9e;
  }
}
