.search-engine {
  h3 {
    margin-left: 5px;
  }

  .search-engine__rule {
    padding: 10px 5px;

    &:nth-child(even) {
      background: #eaeaea;
    }

    .deleteIcon {
      margin: 10px;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .search-engine__selector {
    margin-left: 5px;
  }

  .search-engine__add-buttons {
    margin-bottom: 20px;

    button {
      margin-left: 5px;
    }
  }

  .search-engine__seach-area {
    margin: 5px;
  }
}










.searchList {
  padding: 10px 0 0 0;
  border: 1px solid $color-2-primary;
  width: 100%;
  display: table
}

.searchListHeader {
  border: 1px solid $color-2-primary;
  background-color: $color-1-primary;
  color: white;
  font-weight: bold;
  display: table-row
}

.cellHeader {
  border-right: 1px solid white;
  display: table-cell;
  width: 12%;
  padding: 1px;
  text-align: center;
}

.rowContent {
  border: 1px solid $color-2-primary;
  background-color: white;
  display: table-row
}

.cellContent {
  border-right: 1px solid white;
  display: table-cell;
  width: 10%;
  padding-right: 4px;
  text-align: center;
  border-bottom: none;
}

.cellContentTwo {
  background-color: rgba(0, 0, 0, 0.1);
}
