.iconFont {
  font-family: Symbols;
}

.videoCallIcon::before {
  content: "\E13B";
}

.emailIcon::before {
  content: "\E119";
}

.messageIcon::before {
  content: "\E15F";
}

.phoneIcon::before {
  content: "\E13A";
}

.mapIcon::before {
  content: "\E1C4";
}

.deviceIcon::before {
  content: "\E1C9";
}

.fileIcon::before {
  content: "\E295";
}

.copyIcon::before {
  content: "\E16F";
}

.appsIcon::before {
  content: "\E1E1";
}

.documentIcon:before {
  content: "\E160";
}

.contactInfoIcon::before {
  content: "\E779";
}

.goToStartIcon::before {
  content: "\E8FC";
}

.peopleIcon::before {
  content: "\E716";
}

.messageIcon:before {
  content: "\E134";
}

.deleteIcon:before {
  content: "\E107";
}

.editIcon:before {
  content: "\E104";
}

.saveIcon:before {
  content: "\E74E";
}

.addIcon:before {
  content: "\E109";
}

.contactIcon:before {
  content: "\E13D";
}

.clearIcon:before {
  content: "\E106";
}

.addIcon:before {
  content: "\E109";
}

.viewIcon:before {
  content: "\E18B";
}

.monthIcon:before {
  content: "\E163";
}

.detailsIcon:before {
  content: "\E14C";
}

.permissionsIcon:before {
  content: "\E192";
}

.supervisionIcon:before {
  content: "\E1DE";
}

.searchIcon:before {
  content: "\E1A3";
}

.cancelIcon:before {
  content: "\E711";
}

.homeIcon:before {
  content: "\E80F";
}

.filesIcon:before {
  content: "\E8C8";
}

.tvMonitorIcon:before {
  content: "\E7F4";
}

.switchAppsIcon:before {
  content: "\E8F9";
}

.settingsIcon:before {
  content: "\E713";
}

.burgerIcon:before {
  content: "\E700";
}

.contactSolidIcon:before {
  content: "\EA8C";
}

.closeIcon:before {
  content: "\E8BB"
}

.PowerButtonIcon:before {
  content: "\E7E8"
}

.selectIcon:before {
  content: "\E73A"
}

.unselectIcon:before {
  content: "\E739"
}

.refreshIcon::before {
  content: "\E72C"
}

.copyIcon::before {
  content: "\E8C8"
}
