// Responsive Font Mixin
@mixin font-source($font-family, $size_tablet: false, $size_desktop: false, $colour: false, $weight: false, $lh: false) {

  font-family: $font-family, "Segoe UI", sans-serif, "Segoe MDL2 Assets", "Symbols", "Segoe UI Emoji";

  @if $size_tablet {
    @include mq('tablet') {
      font-size: $size_tablet;
    }
  }

  @if $size_desktop {
    @include mq('desktop') {
      font-size: $size_tablet;
    }
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh;
  }
}

// Keep images from scaling beyond the width of their parents.
@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

// Transtions
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}


@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content ;
  }

  @-moz-keyframes #{$animation-name} {
    @content ;
  }

  @-ms-keyframes #{$animation-name} {
    @content ;
  }

  @-o-keyframes #{$animation-name} {
    @content ;
  }

  @keyframes #{$animation-name} {
    @content ;
  }
}

@mixin animation($str) {
  @include css3-prefix('animation', $str);
}
