.list-pane {

  .list-pane__content {

    .win-selectionbackground {
      background-color: $color-1-primary !important;
    }

    .win-listlayout {
      margin-top: 0;
    }

    .win-surface {
      margin-top: 0 !important;
      white-space: nowrap;
    }

    .win-container {
      margin: 0 !important;
    }

    .win-item {
      padding-top: 8px;
      padding-bottom: 8px;
      width: calc(100% - 24px);
      padding-left: 12px;

      .list-pane__picture {
        width: 42px;
        height: 42px;
        background-size: cover;
        -webkit-border-radius: 42px;
        -moz-border-radius: 42px;
        border-radius: 42px;
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;
      }

      .list-pane__name,
      .list-pane__detail {
        vertical-align: middle;
        width: 240px;
        $white-color-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
      }

      .list-pane__name {
        font-size: 16px;
        line-height: 22px;
      }

      .list-pane__detail {
        color: $text-detail-color;
        font-size: 13px;
        line-height: 16px;
      }
    }

    .win-groupheadercontainer {
      width: 32px;
      height: 32px;
      margin-top: 7px !important;
      margin-bottom: 10px;
      margin-left: 12px;
    }

    .win-groupheader {
      font-size: 15px;
      width: 32px;
      height: 32px;
      padding: 0;
      background-color: $app-color;
      color: $color-2-primary;
      text-align: center;
      line-height: 32px;
    }

  }
}

.listToolBar .win-toolbar-actionarea {
  background-color: $white-color !important;
}

.win-commandingsurface .win-commandingsurface-actionarea {
  justify-content: space-around !important;
}
