.error-message {
  color: $alert;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.error-input,
.error-input:focus {
  border-color: $alert !important;
}
