%normalize-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

%normalize-anchor {
  text-decoration: none;
  color: unset;

  &:hover {
    text-decoration: none;
  }
}

%box-shadow {
  @include css3-prefix(box-shadow, "0 1px 1px 0 rgba(0,0,0,0.1), 0 -1px 2px 0 rgba(0,0,0,0.1)");
}

%content-with-scroll {
  overflow-y: auto;
  height: calc(100vh - #{ $header-height }) !important;
}

%content-pane {
  overflow-y: auto;
  padding: 0;
  width: auto;

  .content-header {
    line-height: 1.5;

    .name {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      padding-top: 6px;
    }

    button {
      float: right;
      height: 20px;
      margin-top: -30px;
    }

    .item-info {
      display: flex;

      .item-info__message {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }

      .item-info__source {
        display: block;
        color: $text-detail-color;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      }
    }

    .list-pane__picture {
      width: 100px;
      height: 100px;
      background-size: cover;
      display: inline-block;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      margin: 15px;
    }
  }

  .content-info {
    ul {
      line-height: 1.5em;
      list-style: none;
      -webkit-margin-before: 1em;
      -webkit-margin-after: 1em;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 20px;
    }
  }
}
