.title {
  font-weight: 300;
  font-size: 15px;
  height: 32px;
  margin: 10px;
  padding: 0 15px;
  background-color: #158784;
  color: #FFFFFF;
  line-height: 32px;
}
