h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 46px;
  font-weight: 200;
  line-height: 1.216;
  letter-spacing: 0px;
}

h2 {
  font-size: 34px;
  font-weight: 200;
  line-height: 1.176;
}

h3 {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.167;
}

h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

h5 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.333;
}

h6 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.333;
}
