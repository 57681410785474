.btn {
  cursor: pointer;
  margin: 0px;
  min-width: 120px;
  min-height: 32px;
  padding: 4px 8px;
  background-clip: border-box;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.333;
  border: none;
  -webkit-appearance: none;
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: 0;
  outline-offset: 0;

  @include transition(background-color, .2s, ease);
  @include font-source(Segoe UI, 15px, #616264);
}

.btn--primary {
  background-color: $color-1-primary;
  color: white;

  &:hover {
    background-color: lighten($color-1-primary, 5);
  }

  &.active {
    background-color: lighten($color-1-primary, 10);
  }
}

.btn--secondary {
  background-color: $color-1-secondary;

  &:hover {
    background-color: lighten($color-1-secondary, 5);
  }

  &.active {
    background-color: lighten($color-1-secondary, 10);
  }
}
