.list-element {
  display: block;
  overflow: auto;

  .list-element__message {
    display: inline-block;
    padding: 10px 0px 10px 15px;
    width: 40%;
    vertical-align: middle;

    &.--without-padding-left {
      padding: 0;
    }
  }

  .list-element__detail {
    color: darken($text-detail-color, 35);
    font-size: 13px;
    line-height: 16px;
  }

  .list-element__controller {
    padding: 10px 15px 10px 0;
    float: right;

    .win-toggleswitch-values {
      display: none;
    }

    .win-toggleswitch-header {
      margin: 0;
    }

    button {
      margin-top: 5px;
    }
  }
}
