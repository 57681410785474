// Theme Colors

$color-1-primary: #158784;
$color-2-primary: #f2f2f2;

$color-1-secondary: #cccccc;
$color-2-secondary: #507675;

$font-color-primary: black;

$white-color: #FFFFFF;

// Color of signal

$alert: #dd0000;
$warning: #ffa600;
$success: #158784;
$info: #00BCD4;

//Color empty message

$empty-message-color:#424242;

// Colors to delete

$text-detail-color: grey;
$border-ligth-color: lighten($color-2-primary, 5);
$app-color: $color-1-primary;

// Colors Split View

$splitview-color-fonts: $font-color-primary;
$splitview-color-fonts-hover: $font-color-primary;

// Colors dropdown

$dropdown-color: #FFFFFF;
$dropdown-color-hover: rgba(0, 0, 0, .1);
