.about {
  height: 100%;

  .win-commandingsurface .win-commandingsurface-actionarea {
    justify-content: flex-end !important;
  }

  .win-autosuggestbox {
    margin: 15px 0 !important;
    float: right;

    input {
      width: 260px !important;
    }
  }

  .search {
    width: 68px;
    height: 48px;
    cursor: pointer;
    font-size: 22px;
    text-align: center;
    line-height: 54px;
    float: right;
    margin-top: 5px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.plugins {
  margin: 15px 0;
}

.plugins--left {
  display: inline-block;
  width: 350px;
  text-overflow: ellipsis;
}

.plugin--right {
  display: inline-block;
  text-align: right;
  width: 100px;
}

.plugin__title {
  font-weight: 400;
}

.plugin__detail {
  font-weight: 200;
}

@media (max-width: 845px) {
  .plugins--left {
    display: inline-block;
    width: 200px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .plugin--right {
    text-align: right;
    width: 100px;
    vertical-align: top;
    float: right;
  }
}

.about-pane {
  height: 100%;

  h1:first-child {
    display: none;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }
}

.itemList {
  padding: 22px 14px 22px 26px;
}

.itemList:hover {
  background-color: #E5E5E5;
}

.feedback {
  margin: 10px;

  textarea {
    max-width: 480px;
    width: 90%;
  }
}

.contact-list {
  @extend %normalize-list;

  >li {
    margin-top: 10px;
  }
}

.separator {
  height: 1px;
  background: #9e9e9e;
  margin-top: 20px;
  margin-bottom: 20px;
}

.date {
  font-size: 13px;
  color: gray;
}
