.empty-message {
  color: $empty-message-color;
  text-align: center;
}

.icon-empty-message {
  color: $empty-message-color;
  padding: 10px;
  font-size: 48px;
}
