.flex-block {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  align-content: flex-start;
  width: 100%;
  height: calc(100vh - #{ $header-height });

  .flex-block__list {
    height: 100%;
    display: inline-block;
    vertical-align: top;
  }

  .flex-block__content {
    display: inline-block;
  }


  .flex-block--with-scroll {
    @extend %content-with-scroll;
  }

  .flex-block--with-content-pane {
    @extend %content-pane;
    flex: 1 100%;
  }
}

.content-pane {
  @extend %content-pane;
  width: 100%;
  overflow-y: auto;
}

.center-block-content {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
