$width-desktop: 200px;
$width-tablet-small: 200px;
$width-phablet: 200px;
$color-fonts: $splitview-color-fonts;
$color-fonts-hover: $splitview-color-fonts-hover;

@include keyframes(expanding-splitview-desktop) {
  0% {
    width: 0;
  }

  90% {
    width: $width-desktop;
  }
}

@include keyframes(expanding-splitview-tablet-small) {
  0% {
    width: 0;
  }

  90% {
    width: $width-tablet-small;
  }
}

@include keyframes(expanding-splitview-phablet) {
  0% {
    width: 0;
  }

  90% {
    width: $width-phablet;
  }
}

@include keyframes(contract-splitview-desktop) {
  0% {
    width: $width-desktop;
  }

  100% {
    width: 0;
  }
}

@include keyframes(contract-splitview-tablet-small) {
  0% {
    width: $width-tablet-small;
  }

  100% {
    width: 0;
  }
}

@include keyframes(contract-splitview-phablet) {
  0% {
    width: $width-phablet;
  }

  100% {
    width: 0;
  }
}


.splitview-block {
  display: inline-block;

  div {
    white-space: nowrap;
  }

  .splitview-wrapper__div {
    height: $splitview-height;
    transition: height 0.25s ease-in-out;
    -webkit-transition: height 0.25s ease-in-out;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    left: 0;
    width: inherit;

    .splitview-wrapped__navbar {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-shrink: 0;
      -ms-flex-negative: 0;
      -webkit-flex-shrink: 0;

      .splitview-wrapper-wrapper__div {
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        background-color: $color-2-primary;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        padding: 16px 0 0;
        overflow-y: auto;
        overflow-x: hidden;
        width: $splitview-width;

        &.--large {
          width: 200px;
        }

        &.--end {
          align-items: baseline;
          -webkit-box-align: baseline;
          -ms-flex-align: baseline;
          -webkit-align-items: left;
        }

        .splitview-wrapped-navbar-wrapped-top__section {
          flex-grow: 1;
          -ms-flex-positive: 1;
          -webkit-flex-grow: 1;
          flex-shrink: 1;
          -ms-flex-negative: 1;
          -webkit-flex-shrink: 1;
          flex-basis: 0;
          -ms-preferred-size: 0;
          -webkit-flex-basis: 0;
        }

        .splitview-wrapped-navbar-wrapped-bottom__section {
          flex-grow: 0;
          -ms-flex-positive: 0;
          -webkit-flex-grow: 0;
        }

        .splitview-wrapped-navbar-wrapped-top__section,
        .splitview-wrapped-navbar-wrapped-bottom__section {

          >div {
            height: 40px;
            text-align: center;

            >a {
              cursor: pointer;
              font-size: $splitview-icon-size;
              color: $color-fonts;

              @extend %normalize-anchor;

              @include transition(all, .2s, ease);

              &:hover {
                color: $color-fonts-hover;
              }

              &:active {
                text-shadow: 1px 1px 1px darken($color-2-primary, 30);
              }
            }
          }

          &.--description {

            >div {
              height: 40px;
              text-align: left;

              span {
                cursor: pointer;
                color: $color-fonts;
                white-space: nowrap;
                overflow: hidden;

                @include transition(all, .1s, linear);

                &:hover {
                  color: $color-fonts-hover;
                }
              }
            }
          }
        }
      }
    }
  }
}
